import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <div>
      <SubHead className="icons">
        <img
          src={require("../Assets/images/left_arrow.png")}
          onClick={() => navigate("/login")}
        />
      </SubHead>
      <SignUpLogoContainer>
        <img src={require("../Assets/images/biju-logo.png")} />
      </SignUpLogoContainer>

      <Container>
        <HeadPart> Privacy Policy</HeadPart>
        <h4>USER INFORMATION</h4>
        Biju & Biju collects information about you and your use of the Platform,
        and the devices used by you to access the Platform including; your
        activity on the platform, which would include your search history, your
        search inputs, pages viewed on the Platform, statistics on page views
        and the URLs with time stamps; device information including your IP
        address, and identifiers such as browser setting type, operating system,
        network information and device type settings including but not limited
        to crash reports, system activity, the date, time and referral URL of
        your request. When you register with us or avail of our products or
        services, you provide us information like your name, age, email address,
        location, and phone number; transaction-related information, such as
        when you make purchases, respond to any offers, or download or use
        Applications from us; information you provide us when you contact us for
        help; information you enter into our system when using the Application/
        Services/ products; the information obtained through GPS or other means,
        such as the geographical location collectively, ‘Information’. In case
        any individual under the age of 18 years of age is utilizing the
        Platform, such usage should be undertaken only with the strict
        supervision and approval of a parent or a legal guardian or any other
        person having parental responsibility for the child, and Biju & Biju
        will not be responsible for the same at any time and in any manner
        whatsoever.
        <br />
        <br />
        <h4>USE OF YOUR USER’S PERSONAL INFORMATION</h4>
        The Information collected by us shall be used for availing our Services
        and utilized for other functions including but not limited to
        <br />
        <br />
        <li>Rendering Services (as defined in T&C of Service).</li>
        <li>
          {" "}
          To resolve any complaints you may have and ensure that you receive the
          highest quality of Services.
        </li>
        <li> Evaluating the quality and competence of our personnel.</li>
        <li> To coordinate order delivery.</li>
        <li>notify you about changes to our Platform.</li>
        <li> Send out system updates on the booked services.</li>
        <li>Detecting, preventing, and addressing technical issues.</li>
        <li> Improve user experience.</li>
        <li> Analyze trends, conduct research.</li>
        <li> Administer the Platform/Services and products.</li>
        <li>
          {" "}
          learn about each user's learning patterns and movements around the
          Platform/Services and products.
        </li>
        <li>
          {" "}
          Gather demographic information and usage behavior about our user base
          as a whole.
        </li>
        <br />
        Aggregated and individual, anonymized, and non-anonymized data may
        periodically be transmitted to external service providers to help us
        improve the Platform, products, and Services. We do not sell, trade, or
        rent your Information to any third party unless saved and except in
        order to provide you with the Services or we have been expressly
        authorized by you either in writing or electronically to do so. We may
        at times provide aggregate statistics about our customers, traffic
        patterns, and related site information to reputable third parties.
        However, this information, when disclosed, will be in an aggregate form
        and does not contain any of your personally identifiable information. We
        are concerned about safeguarding the confidentiality of your
        Information. We provide physical, electronic, and procedural safeguards
        to protect the Information we process and maintain. We follow a strict
        confidentiality policy with regard to the disclosure of confidential
        information to our employees or other personnel. There may be situations
        where we may disclose confidential information only to those of our
        employees and other personnel on a need-to-know basis. Any breach of
        confidential information by the employees or personnel within the
        Company is dealt with stringently by us.
        <br />
        <br />
        <h4>USER RIGHTS</h4>
        You can always choose not to provide the requested information to us; it
        will however result in you not availing certain features if, or the
        entire, of our Services. With respect to Information you will have the
        following rights/preferences: access, confirm, and review Information
        you may have provided; correct Information that may be inaccurate or
        irrelevant; deletion and erasure of your Information from the publicly
        available pages of the Platform; receive Information we hold about you
        in a portable format; object to or restrict any form of processing you
        may not be comfortable with; however, this will result in
        discontinuation of your use of the Services. In order to exercise these
        rights, please contact us at the email address info@bijuandbiju.in If
        you want to withdraw your consent or raise any objection to the use of
        your Information for receiving any direct marketing information to which
        you previously opted-in, you can do so by contacting our customer
        support at the above-mentioned addresses. If you withdraw/object your
        consent, our use of your Information before you withdrew/object shall
        still be lawful.
        <br />
        <br />
        <h4>TRANSFER OF USER’S INFORMATION</h4>
        Your information may be transferred to, and maintained on, computers
        located outside of your state, province, country, or other governmental
        jurisdiction where the data protection laws may differ from those from
        your jurisdiction. Your consent to this Policy followed by your
        submission of such information represents your agreement to that
        transfer. We will take all steps reasonably necessary to ensure that
        your Information is treated securely and in accordance with this Privacy
        Policy.
        <br />
        <br />
        <h4>THIRD-PARTY SERVICES</h4>
        We will share your information with third parties only in the ways that
        are described below in this Policy and use the individual data and
        behavior patterns combined with personal information to provide you with
        personalized content. Third parties provide certain services which we
        may use to analyze Information to personalize, drive insights and help
        us better your experience or reach out to you with more value-added
        applications, products, information, and Services. Biju & Biju will not
        be liable for the privacy practices of such third-party entities and
        encourages you to carefully read the privacy policies and procedures of
        such third parties. We may use your Information to send you promotional
        information about third parties which, we think you may find interesting
        if you tell us that you wish this to happen. We shall not be responsible
        for any disclosure of Information due to unauthorized third-party access
        or other acts of third parties or acts or omissions beyond our
        reasonable control and you agree that you will not hold us responsible
        for any breach of security unless such breach has been caused as a
        direct result of our negligence or wilful default. We use support
        services of third-party platforms and/or companies to direct you to
        payment gateways when you opt to pay for our Services. Your financial
        information is collected, stored, and retained by such third-party
        platforms. We and such third-party platforms undertake measures designed
        to provide a security level that is appropriate to the risks of
        processing your personal information and shall comply with applicable
        laws. However, you are requested to check and consent to the “Privacy
        Policy” of such third-party platforms in order to accept how such
        third-party platforms handle your Information. We may disclose
        Information: as required by law, such as to comply with a subpoena or
        similar legal process; to enforce applicable T&C, including
        investigation of potential violations thereof; when we believe in good
        faith that disclosure is necessary to protect our rights, protect your
        safety or the safety of others, investigate fraud, address security or
        technical issues or respond to a government request; with our trusted
        services providers who work on our behalf, do not have an independent
        use of the Information we disclose to them and have agreed to adhere to
        the T&C set forth in this Policy; to protect against imminent harm to
        the rights, property or safety of the Platform/ Biju & Biju or its users
        or the public as required or permitted by law; with third-party service
        providers in order to personalize the Platform/Services/products for a
        better user experience and to perform behavioral analysis. We may share
        Information upon the merger or acquisition of Biju & Biju with another
        company. We shall transmit and transfer the Information upon acquisition
        or merger of Biju & Biju with another company. Once you leave the
        Platform, we are not liable for any use/ storage/ processing/ collection
        of your Information obtained by any third-party websites or payment
        facilitators or provided by you to these third parties or payment
        facilitators. Such entities and their respective websites/platforms may
        be governed by their own “Privacy Policy” and “T&C of Service”, which
        are beyond our control. When you use certain features on our Platform
        like the discussion forums, and you post or share your personal
        information such as comments, messages, files, and photos, will be
        available to all users, and will be in the public domain. All such
        sharing of information is done at your own risk. Please keep in mind
        that if you disclose Personal Information in your profile or when
        posting on our forums, this information may become publicly available.
        <br />
        <br />
        <h4>COMMUNICATION WITH USER </h4>
        Biju & Biju will occasionally send email notices or contact you to
        communicate about our Services, products, and benefits, as they are
        considered an essential part of the Services/products you have chosen.
        We may alert you by email or phone (through RCS/ SMS/ Phone Call) to
        inform you about new service offerings or other information which we
        feel might be useful for you. When you send an email or other
        communication to us, we may retain those communications in order to
        process your inquiries, respond to your requests, and improve our
        Services/products.
        <br />
        <br />
        <h4>USER DATA RETENTION</h4>
        Currently, we plan to retain user Information while an account is active
        and for at least three years afterward. We may alter this practice
        according to legal and business requirements. For example, we may
        lengthen the retention period for some data if needed to comply with law
        or voluntary codes of conduct. Unless otherwise prohibited, we may
        shorten the retention period for some types of data if needed to free up
        storage space. We will delete Information based on a request received
        from you within a reasonable period and the latest within ninety days of
        receiving a deletion request. However, we may retain such portions of
        Information and for such periods as may be required under applicable
        law. Notwithstanding anything contained herein, Biju & Biju may retain
        data after account deletion for reasons including but limited to the
        following purposes: if there is an unresolved issue relating to your
        account, or an unresolved claim or dispute; if we are required to by
        applicable law; and/or in aggregated and/or anonymized form; or Biju &
        Biju may also retain certain information if necessary for its legitimate
        business interests, such as fraud prevention and enhancing the user’s
        safety and security.
        <br />
        <br />
        <h4>LOG INFORMATION & COOKIES</h4>
        When you access our Platform, our servers magically record information
        that your browser sends whenever you visit a website. These server logs
        may include information such as your web request, internet protocol
        address, browser type, browser language, the date and time of your
        request, and one or more cookies that may uniquely identify your
        browser. We send cookies (small files containing a string of characters)
        to your computer, thereby uniquely identifying your browser. Cookies are
        used to track your preferences, help you log in faster, and aggregate to
        determine user trends. This data is used to improve our offerings, such
        as providing more content in areas of greater interest to a majority of
        users. Most browsers are initially set up to accept cookies, but you can
        reset your browser to refuse all cookies or to indicate when a cookie is
        being sent. Some of our features and services may not function properly
        if your cookies are disabled.
        <br />
        <br />
        <h4>CHANGES TO THIS STATEMENT</h4>
        We may modify or revise the Policy from time to time and shall
        accordingly notify you of any changes to the Privacy Policy by posting
        the revised Privacy Policy on the Platform with an updated date of
        revision. We shall endeavor to review, revise, update, modify, amend or
        correct the Policy on a regular and routine basis, especially whenever a
        significant update is made to the technology employed by us. You must
        periodically review the Policy for the latest information on our privacy
        practices. In the event you continue to use the Platform and our
        Services after any update in the Policy, your use of the Platform shall
        be subject to such updated policy. Your continued usage of Services
        posts any amendment would deem to mean that you accept and understand
        the revised Policy. Further, we retain the right at any time to deny or
        suspend access to all, or any part of, the service to anyone who we
        reasonably believe has violated any provision of this Policy.
        <br />
        <br />
        <h4>USER CONSENT</h4>
        We believe that every user of our Services/products/Platform must be in
        a position to provide informed consent prior to providing any
        Information required for the use of the Services/products/Platform. By
        registering with the Platform, you are expressly consenting to our
        collection, processing, storing, disclosing, and handling of your
        Information as set forth in this Policy now and as amended by us.
        Processing your Information in any way, including, but not limited to,
        collecting, storing, deleting, using, combining, sharing, transferring,
        and disclosing information, all of which activities will take place in
        India. This Policy shall be enforceable against you in the same manner
        as any other written agreement. By visiting or accessing the Platform
        and voluntarily providing us with Information, you are consenting to our
        use of the Information in accordance with this Privacy Policy. If you do
        not agree with this Policy, you may refuse or withdraw your consent at
        any time or alternatively choose to not provide us with any Information.
        Under such circumstances, we may be unable to render Services. Such an
        intimation to withdraw your consent can be sent to info@bijuandbiju.in.
        <br />
        <br />
        <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
        Except as mentioned below, all information, content, material,
        trademarks, services marks, trade names, and trade secrets, including
        but not limited to the software, text, images, graphics, video, script,
        and audio, contained on the Platform, Services, and products are the
        proprietary property of Biju & Biju (“Proprietary Information”). The
        products of Biju & Biju shall include but shall not be limited to the
        curated course material designed and published by Biju & Biju and
        provided to you for delivering the Services to you. Biju & Biju shall at
        all times retain full and complete title to the information, content, or
        materials and all Proprietary Information provided by the Company on any
        medium. No Proprietary Information may be copied, downloaded,
        reproduced, modified, republished, uploaded, posted, transmitted, or
        distributed in any way without obtaining prior written permission from
        the Company, and nothing on the Platform or Services shall be or
        products deemed to confer a license of or any other right, interest or
        title to or in any of the intellectual property rights belonging to the
        Company, to you. Certain contents on the Platform may belong to third
        parties. Such contents have been reproduced after taking prior consent
        from said party and all rights relating to such content will remain with
        such third party. Further, you recognize and acknowledge that the
        ownership of all trademarks, copyright, logos, service marks, and other
        intellectual property owned by any third party shall continue to vest
        with such party and you are not permitted to use the same without the
        consent of the respective third party. Your use of our products,
        platforms, and Services is solely for your personal use. Any use of the
        Platform, Services, or products or their contents other than for
        personal purposes is prohibited. Your use of our Platform or Services
        does not constitute any right or license for you to use our service
        marks or trademarks without the prior written permission of Biju & Biju.
        Your personal and non-commercial use of this Platform, products, and/or
        our Services shall be subjected to the following restrictions. By
        submitting content on or through the Services (your “Material”), you
        grant us a worldwide, non-exclusive, royalty-free license (with the
        right to sublicense) to use, copy, reproduce, process, adapt, modify,
        publish, transmit, display and distribute such Material in any and all
        media or distribution methods (now known or later developed) and to
        associate your Material with you, except as described below. You agree
        that others may use your Material in the same way as any other content
        available through the Services. Other users of the Services may fork,
        tweak and repurpose your Material in accordance with this Policy and the
        T&C contained herein. If you delete your user account, your Material and
        name may remain available through the Services. You may not decompile,
        reverse engineer, or disassemble the contents of the Products/ Platform
        / Services or modify, copy, distribute, transmit, display, perform,
        reproduce, publish, license, create derivative works from, transfer, or
        sell any information or software obtained from the Website, or remove
        any copyright, trademark registration, or other proprietary notices from
        the contents of the Website /Services/Products. You will not (a) use our
        Platform and/or any of our product/s or Service/s for any unauthorized
        commercial purposes of any kind, or (b) advertise or sell any products,
        Services or domain names or otherwise (whether or not for profit), or
        solicit others (including, without limitation, solicitations for
        contributions or donations) or use any public forum for commercial
        purposes of any kind, or (c) use the Platform/our products and Services
        in any way that is unlawful, or harms Biju & Biju or any other person or
        entity as determined by Biju & Biju. No User shall be permitted to
        perform any of the following prohibited activities while availing our
        Services: making available any content that is misleading, unlawful,
        harmful, threatening, abusive, tortious, defamatory, libelous, vulgar,
        obscene, child- pornographic, lewd, lascivious, profane, invasive of
        another's privacy, hateful, or racially, ethnically or otherwise
        objectionable; stalking, intimidating and/or harassing another and/or
        inciting other to commit violence; transmitting material that encourages
        anyone to commit a criminal offence, that results in civil liability or
        otherwise breaches any relevant laws, regulations or code of practice;
        interfering with any other person's use or enjoyment of the
        Platform/Services; making, transmitting or storing electronic copies of
        materials protected by copyright without the permission of the owner,
        committing any act that amounts to the infringement of intellectual
        property or making available any material that infringes any
        intellectual property rights or other proprietary rights of anyone else;
        make available any content or material that you do not have a right to
        make available under any law or contractual or fiduciary relationship,
        unless you own or control the rights thereto or have received all
        necessary consents for such use of the content; impersonate any person
        or entity, or falsely state or otherwise misrepresent your affiliation
        with a person or entity; post, transmit or make available any material
        that contains viruses, trojan horses, worms, spyware, time bombs,
        cancelbots, or other computer programming routines, code, files or such
        other programs that may harm the services, interests or rights of other
        users or limit the functionality of any computer software, hardware or
        telecommunications, or that may harvest or collect any data or personal
        information about other Users without their consent; access or use the
        Platform/Services/Products in any manner that could damage, disable,
        overburden or impair any of the Platform’s servers or the networks
        connected to any of the servers on which the Website is hosted;
        intentionally or unintentionally interfere with or disrupt the Services
        or violate any applicable laws related to the access to or use of the
        Platform/Services/products, violate any requirements, procedures,
        policies or regulations of networks connected to the
        Platform/Services/products, or engage in any activity prohibited by
        Policy and the T&C contained herein; disrupt or interfere with the
        security of, or otherwise cause harm to, the Website/Services/Products,
        materials, systems resources, or gain unauthorized access to user
        accounts, passwords, servers or networks connected to or accessible
        through the Platform/Services/Products or any affiliated or linked
        sites; interfere with, or inhibit any user from using and enjoying
        access to the Platform/ Services/products, or other affiliated sites, or
        engage in disruptive attacks such as denial of service attack on the
        Platform/Services/products; use deep-links, page- scrape, robot, spider
        or other matic device, program, algorithm or methodology, or any similar
        or equivalent manual process, to increase traffic to the
        Platform/Services/products, to access, acquire, copy or monitor any
        portion of the Platform/Services/products, or in any way reproduce or
        circumvent the navigational structure or presentation of the or any
        content, to obtain or attempt to obtain any content, documents or
        information through any means not specifically made available through
        the Platform/Services/products; alter or modify any part of the
        Services; use the Services for purposes that are not permitted by: (i)
        the Policy; and (ii) any applicable law, regulation or generally
        accepted practices or guidelines in the relevant jurisdiction; or
        violate any of the T&C specified under the T&C for the use of the
        Platform/Services/products.
        <br />
        <br />
        <h4>CONSEQUENCES OF BREACH & INDEMNIFICATION:</h4>
        In the event of a breach of the Policy, you shall be liable to pay
        liquidated damages along with costs of recovery of the damages to the
        Company. You shall be further liable to compensate Biju & Biju for the
        losses incurred by Biju & Biju due to the breach of Policy by you. Biju
        & Biju shall be entitled to immediately suspend/terminate the Services
        being provided to you if any of the T&C of the Policy is breached by
        you. In no event shall Biju & Biju, its officers, directors, employees,
        partners, or agents be liable to you or any third party for any special,
        incidental, indirect, consequential, or punitive damages whatsoever,
        including those resulting from loss of use, data or profits or any other
        claim arising out, of or in connection with, your use of, or access to
        the Platform. You agree to defend, indemnify and hold harmless Biju &
        Biju, its officers, directors, employees, and agents, from and against
        any and all claims, damages, obligations, losses, liabilities, costs or
        debt, and expenses (including but not limited to attorney’s fees)
        arising from breach of any of the T&C of the Policy.
        <br />
        <br />
        <h4>MISCELLANEOUS</h4>
        In the preparation of the Platform/Services/products and contents
        therein, every effort has been made to offer the most current, correct,
        and clearly expressed information possible. Nevertheless, inadvertent
        errors may occur. In particular, but without limiting anything here,
        Biju & Biju disclaims any responsibility for any errors and accuracy of
        the information that may be contained on the Platform. Any feedback from
        you is most welcome to make the contents thereof error-free and
        user-friendly. Biju & Biju also reserves the right and discretion to
        make any changes/corrections or withdraw/add content at any time without
        notice. Neither Biju & Biju nor any third parties provide any warranty
        or guarantee as to the accuracy, timeliness, performance, completeness,
        or suitability of the information and materials found or offered on
        Platform/Services/products for any particular purpose. You acknowledge
        that such information and materials may contain inaccuracies or errors,
        and we expressly exclude liability for any such inaccuracies or errors
        to the fullest extent permitted by applicable law. This Policy and any
        other guidelines made applicable to the Platform/Services from time to
        time constitute the entire agreement between Biju & Biju and you with
        respect to your access to or use of the Platform and the Services
        thereof. You cannot assign or otherwise transfer your obligations under
        the Policy or any right granted hereunder to any third party. Biju &
        Biju’s rights under the Policy are freely transferable by Biju & Biju to
        any third parties without the requirement of seeking your consent. If
        for any reason, a court of competent jurisdiction finds any provision of
        the T&C, or portion thereof, to be unenforceable, that provision shall
        be enforced to the maximum extent permissible so as to give effect to
        the intent of the parties as reflected by that provision, and the
        remainder of the Policy shall continue in full force and effect. All
        notices served by the Company shall be provided via email to the User
        account or as a general notification on the Application. Any notice to
        be provided to the Company should be sent to the registered office of
        the Company. Any failure by Biju & Biju to enforce or exercise any
        provision of the Policy, or any related right, shall not constitute a
        waiver by Biju & Biju of that provision or right. Biju & Biju provides
        the Policy so that you are aware of the T&C that applies to your use of
        the Platform and Services. You acknowledge that, Biju & Biju has given
        you a reasonable opportunity to review this Policy and that you have
        agreed to them. This Policy and any obligation arising from and in
        connection with this Policy shall be governed by the laws of the
        Republic of India. Any dispute or difference relating to any of the
        matters set out in this Policy, and the courts of Mumbai shall have
        exclusive jurisdiction over any and all disputes relating to this
        Policy.
        <br />
        <br />
        <h4>USER SUPPORT AND REDRESSAL MECHANISM</h4>
        The Company offers an email as well as phone call-based support system
        along with an online knowledge pool of FAQs. In case you require any
        assistance or support, you may access support resources or contact our
        support by emailing info@bijuandbiju.in or calling our helpline at +91
        9847032212(Monday to Sunday between 8 am and 8 pm). Any discrepancies or
        grievances with regard to user information or privacy policy shall be
        taken up with the designated Grievance Officer appointed from time to
        time, in writing at the registered office address or through email at
        info@bijuandbiju.in signed with the electronic signature Please note
        that for the purpose of validation, you shall be required to provide
        information (including, but not limited to contact number or registered
        mobile number, etc.) for the purpose of validation and taking your
        service request.
      </Container>
    </div>
  );
}

export default PrivacyPolicy;

const Container = styled.div`
  background: #ffffff;
  padding: 2px 75px 50px 15px;
  text-align: justify;
  font-family: sans-serif;
`;
const HeadPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464e5f;
  font-weight: 700;
  font-size: 18px;
  margin-top: 25px;
`;

const SignUpLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  margin-right: 54px;
`;
const SubHead = styled.div`
  padding: 26px 1px 0px 16px;
  &.icons {
    cursor: pointer;
  }
`;
